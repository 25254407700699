.tabForm {
  width: 60%;
}

.spinnerOverlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.submitButton {
  width: fit-content;
}
