.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}

.detailsExplanationField {
  display: flex;
  gap: 16px;
  width: 100%;
}

.textFieldName {
  width: 100%;
}
